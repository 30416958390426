.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.khaobhai {
  margin-top: 50px;
  font-family: 'Poppins', sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 90px;
  padding-top: 150px;
  font-size: 2.5rem;
  line-height: 50px;
}

@media screen and (max-width: 600px) {
  .khaobhai {
    padding: 10px;
    margin-top: 0px;
  }
}

.Toastify__toast-body {
  font-size: 14px;
  /* Adjust the font size as needed */
}