*,
*::before,
*::after {
  margin: 0;
  padding: 0;

  list-style-type: none;
}



::-webkit-scrollbar {
  width: 1.3rem;
}

::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background: #797979;
  transition: all 0.5s ease-in-out;
}

::-webkit-scrollbar-thumb:hover {
  background: #222224;
}

::-webkit-scrollbar-track {
  background: #f9f9f9;
}





.swiper_container {
  height: 52rem;
  padding: 2rem 0;
  position: relative;
  width: "100%";
}

.swiper-slide {
  width: 26rem;
  height: 42rem;
  position: relative;
}

/* @media (max-width: 500px) {
  .swiper_container {
    height: 47rem;
  }
  .swiper-slide {
    width: 28rem !important;
    height: 36rem !important;
  }
  .swiper-slide img {
    width: 28rem !important;
    height: 36rem !important;
  }
} */

.swiper-slide img {
  width: 22rem;
  height: 55rem;
  border-radius: "15px";
  /* object-fit: cover; */
}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
  display: none;
}

.swiper-pagination {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 15rem !important; */
  bottom: 1rem;
}

.swiper-pagination .swiper-pagination-bullet {
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.swiper-pagination .swiper-pagination-bullet-active {
  background: blue;
}

.swiper-3d .swiper-slide,
.swiper-3d .swiper-cube-shadow {
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-button-prev {
  background-image: url(./left-arrow.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

.swiper-button-prev::after {
  display: none;
}
.swiper-button-next {
  background-image: url(./right-arrow.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

.swiper-button-next::after {
  display: none;
}


/* ------------------------------------------ */



.swiper-full-mobile {
  position: relative;
  margin: 50px auto;
  padding-bottom: 50px;
}

.swiper-full-mobile:before {
  content: "";
  width: 28rem;
  background: url(http://md-aqil.github.io/images/Pixel-3-XL.png);
  background-size: 100%;
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  right: 0;
  top: 0px;
  height: 565px;
  z-index: 2;
  margin: auto;
}
.swiper-full-mobile .swiper-slide {
height: 100%;
}
.swiper-full-mobile .swiper-slide img{
  width: 25rem;
  height: 55rem;
object-fit: cover;

}
.swiper-slide.swiper-slide-active img {
border-radius: 40px;

}


.swiper_container.container{
  width: "100%";
}





/* .mobile-frame > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
} */
